import React from "react";
import PropTypes from "prop-types";
import { GiStairs } from "@react-icons/all-files/gi/GiStairs";
import { GiRolledCloth } from "@react-icons/all-files/gi/GiRolledCloth";
import { GiTable } from "@react-icons/all-files/gi/GiTable";
import { FaDumbbell } from "@react-icons/all-files/fa/FaDumbbell";
import { FaChair } from "@react-icons/all-files/fa/FaChair";
import { FaToiletPaper } from "@react-icons/all-files/fa/FaToiletPaper";
import PreviewCompatibleImage from "./PreviewCompatibleImage";
import { Link } from "gatsby";

class TrainingVideosListing extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges;
    const workoutCards = posts.map((post) => {
      let icons = post.node.frontmatter.equipment
        ? post.node.frontmatter.equipment.map((item) => {
            if (item === "dumbbell") return <FaDumbbell size={20} />;
            else if (item === "chair") return <FaChair size={20} />;
            else if (item === "steps") return <GiStairs size={25} />;
            else if (item === "mat") return <GiRolledCloth size={25} />;
            else if (item === "toiletpaper") return <FaToiletPaper size={20} />;
            else if (item === "bench") return <GiTable size={25} />;
            return null;
          })
        : null;
      return (
        <div key={post.node.fields.slug} className="column is-3">
          <div className="card">
            {post.node.frontmatter.thumbnail && (
              <div className="card-image">
                <figure className="image is-16by9">
                  {post.node.frontmatter.thumbnail ? (
                    <div className="featured-thumbnail">
                      <PreviewCompatibleImage
                        imageInfo={{
                          image: post.node.frontmatter.thumbnail,
                          alt: `featured image thumbnail for post ${post.title}`,
                        }}
                      />
                    </div>
                  ) : null}
                  {post.node.frontmatter.thumbnail ? (
                    <div className="featured-thumbnail">
                      <Link to={post.node.fields.slug}>
                        <img
                          src={
                            post.node.frontmatter.thumbnail.childImageSharp
                              .fluid.src
                          }
                          alt={post.node.frontmatter.title}
                        />
                      </Link>
                    </div>
                  ) : null}
                </figure>
              </div>
            )}
            <div className="card-content">
              <div className="media">
                <div className="media-content">
                  <div className="title-container" style={{ height: "3rem" }}>
                    <p className="has-text-centered">
                      <Link
                        to={post.node.fields.slug}
                        className="workout-video-title"
                      >
                        {post.node.frontmatter.title}
                      </Link>
                    </p>
                  </div>
                  <div
                    className="description-container"
                    style={{ height: "4rem" }}
                  >
                    <p className="subtitle" style={{ fontSize: ".75rem" }}>
                      {post.node.frontmatter.description}
                    </p>
                  </div>
                </div>
              </div>

              {!!post.node.frontmatter.length && (
                <footer
                  className="card-footer"
                  style={{
                    paddingTop: "0.5rem",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <p className="card-footer-item has-text-weight-semibold is-size-6">
                      {post.node.frontmatter.length} MINS
                    </p>
                  </div>
                  <div className={!!icons ? "icons" : ""}>{icons}</div>
                </footer>
              )}
            </div>
          </div>
        </div>
      );
    });

    return (
      <div className="workout-video-listing" style={{ padding: "2rem" }}>
        <div className="columns is-multiline has-text-centered">
          {workoutCards}
        </div>
      </div>
    );
  }
}

TrainingVideosListing.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

const TrainingVideosListingFinal = ({
  pageContext: {
    itemList: { data },
  },
}) => <TrainingVideosListing data={data} />;

export default TrainingVideosListingFinal;
