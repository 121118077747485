import React from "react";
import { compose } from "recompose";
import {
  withAuthorization,
  withActiveSubscription,
} from "../../components/Session";
import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import Seo from "../../components/seo";
import TrainingVideosListing from "../../components/TrainingVideosListing";
import TrainingVideoTags from "../../components/TrainingVideoTags";

const TrainingVideosIndexPageBase = ({ pageContext }) => {
  return (
    <div className="video-collection-page">
      <Seo
        title="Video Collection"
        description="Video collection including exercise demos, stretches, warm-ups and home workouts!"
      />
      <PageHeader title={"Video Collection"} />

      {pageContext.item === undefined && <TrainingVideoTags />}
      {pageContext.item && <TrainingVideosListing pageContext={pageContext} />}
    </div>
  );
};

const condition = (authUser) => !!authUser;

const TrainingVideosIndexPage = compose(
  withAuthorization(condition),
  withActiveSubscription
)(TrainingVideosIndexPageBase);

const TrainingVideosIndexPageFinal = ({ pageContext }) => (
  <Layout>
    <TrainingVideosIndexPage pageContext={pageContext} />
  </Layout>
);

export default TrainingVideosIndexPageFinal;
