import React from "react";
import { Link } from "gatsby";
import * as ROUTES from "../constants/routes";
import youtubeImage from "../../static/img/YouTubeSection.png";

export default function TrainingVideosTags() {
  return (
    <>
      <div className="video-collection-grid">
        <div className="video-collection-1">
          <div className="title-bar">
            <span>
              <Link className="video-link" to={ROUTES.EXERCISE_LIBRARY}>
                Exercises
              </Link>
            </span>
          </div>
        </div>
        <div className="video-collection-2">
          <div className="title-bar">
            <span>
              <Link className="video-link" to="/training-videos/tag/Stretch">
                Stretches
              </Link>
            </span>
          </div>
        </div>

        <div className="video-collection-3">
          <div className="title-bar">
            <span>
              <Link className="video-link" to="/training-videos/tag/Warm-up">
                Warm Ups
              </Link>
            </span>
          </div>
        </div>
        <div className="video-collection-4">
          <div className="title-bar">
            <span>
              <Link className="video-link" to="/training-videos/workouts">
                Workouts
              </Link>
            </span>
          </div>
        </div>
      </div>
      <div className="youtube-section">
        <img
          src={youtubeImage}
          alt="YouTube channel preview on mobile device"
        />
        <span className="centered">
          <Link
            className="video-link subscribe"
            to="https://www.youtube.com/channel/UCmE1Qi0qlRgwgpDO50F1Dtg"
          >
            Click here and subscribe to my YouTube channel for even more
            content!
          </Link>
        </span>
      </div>
    </>
  );
}
